<template>
  <div>
    <v-card class="mx-auto pa-5 mt-16" elevation="3" outlined max-width="450px">
      <v-card-text align="center">
        <p class="display-1 text--primary">Sign In</p>
        <p>Selamat Datang, Silahkan Login</p>
      </v-card-text>
      <form align="center">
        <v-text-field
          dense
          outlined
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          type="email"
          required
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="password"
          :rules="passwordRules"
          label="Password"
          required
          type="text"
        ></v-text-field>
        <v-btn @click="submit()">Sign In</v-btn>
        <v-card-subtitle>
          Belum punya akun? <a v-on:click="register"> Sign Up </a>
        </v-card-subtitle>
      </form>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      {{ error_message }}
    </v-snackbar>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const auth = getAuth();

export default {
  name: "Login",
  data: () => ({
    // error: "",
    load: false,
    snackbar: false,
    snackbarColor: "",
    error_message: "",
    timeout: 2000,
    color: "",
    valid: false,
    email: "",
    password: "",
    passwordRules: [(v) => !!v || "Password tidak boleh kosong :("],
    emailRules: [(v) => !!v || "E-mail tidak boleh kosong :("],
    //eslint-disable-next-line
    reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
  }),
  computed: {},

  methods: {
    register() {
      this.$router.push("/register");
    },

    snackbarAction(color, message, timeout) {
      this.snackbarColor = color;
      this.error_message = message;
      this.timeout = timeout;
      this.snackbar = true;
    },

    checker() {
      if (this.email === "" || this.password === "") {
        this.snackbarAction(
          "red",
          "Email dan Password tidak boleh kosong!",
          2000
        );
      } else if (!this.reg.test(this.email)) {
        this.snackbarAction("red", "Format Email Anda Salah", 2000);
      } else {
        return true;
      }
      return false;
    },

    submit() {
      if (this.checker()) {
        signInWithEmailAndPassword(auth, this.email, this.password)
          .then(() => {
            this.snackbarAction("green", "Login berhasil", 2000);
          })
          .catch((error) => {
            var errorCode = error.code;
            if (errorCode === "auth/user-not-found") {
              this.snackbarAction("red", "User tidak ditemukan", 2000);
            } else {
              this.snackbarAction("red", "Error Connection", 2000);
            }
          });
      }
    },

    test() {
      console.log("test");
      getAuth()
        .getUserByEmail("kevinghebre20@gmail.com")
        .then((userRecord) => {
          // See the UserRecord reference doc for the contents of userRecord.
          console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
        })
        .catch((error) => {
          console.log("Error fetching user data:", error);
        });
    },

    clear() {
      //Clear form login
      this.$refs.form.reset();
    },
  },
};
</script>
